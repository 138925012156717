<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="ordertype==1?'添加银行卡':'添加USDT'"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<div class="nav_wrap">
			<div class="nav_item" :class="{active_nav:ordertype==1}" @click="selorder(1)">银行卡</div>
			<div class="nav_item" :class="{active_nav:ordertype==2}" @click="selorder(2)">USDT</div>
		</div>
		<van-row v-if="ordertype==1">
			  <div class="form_cell_item">
				  <div class="form_cell_label">姓名</div>
				  <input  placeholder="请输入姓名 " v-model="bankform.name"/>
			  </div>
			  <div class="form_cell_item">
				  <div class="form_cell_label">银行账号</div>
				  <input placeholder="请输入银行账号 " v-model="bankform.bankaccntno"/>
			  </div>
			  <div class="form_cell_item">
				  <div class="form_cell_label">银行名称</div>
				  <input placeholder="请输入银行名称" v-model="bankform.bankname"/>
			  </div>
			  <div class="form_cell_item">
				  <div class="form_cell_label">开户支行</div>
				  <input placeholder="请输入开户支行" v-model="bankform.kaihuhang"/>
			  </div>
			<van-button type="primary" style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
				@click="saveusdt">
				保存
			</van-button>
		</van-row>
		<van-row v-if="ordertype==2">
			<div class="lable_title">{{$t('tix.lname')}}</div>
			<div class="radio_wrap">
				<div class="radio_item" 
					:class="{active_radio:usdtform.bankname==item.text}" 
					v-for="(item,index) in product"
					@click="selpro(item,index)">{{item.text}}</div>
			</div>
			<div class="lable_title">收款链接</div>
			<div class="form_item">
				<input placeholder="收款链接Url" v-model="usdtform.bankaccntno"/>
			</div>
			<van-button type="primary" style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
				@click="saveusdt">
				保存
			</van-button>
		</van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productvalue: 0,
	  wallet:[],
	  product:[
		  {text:"Trc"},
		  {text:"Erc"},
	  ],
      finished: true,
      loading: false,
	  ordertype:1,
	  usdtform:{
		  type:2,
		  bankname:"Trc",
		  bankaccntno:""
	  },
	  bankform:{
		  type:1,
		  name:"",
		  bankaccntno:"",
		  bankname:"",
		  kaihuhang:""
	  }
    }
  },
  created() {
	  if(this.$route.params.type){
		this.ordertype=this.$route.params.type;
	  }
    /* this.getRecharges()
    this.getTransfers() */
	//this.getinfo();
  },
  mounted() {},
  methods: {
	  selpro(item,index){
		  this.usdtform.bankname=item.text;
	  },
	  async saveusdt(){
		  let  postform = {};
		  if(this.ordertype==1){
			  if(this.bankform.name==''){
				  return this.$toast.fail('请输入姓名');
			  }
			  if(this.bankform.bankaccntno==''){
				  return this.$toast.fail('请输入银行账号');
			  }
			  if(this.bankform.bankname==''){
				  return this.$toast.fail('请输入银行名称');
			  }
			  if(this.bankform.kaihuhang==''){
				  return this.$toast.fail('请输入开户支行');
			  }
			  postform = this.bankform;
		  }
		  if(this.ordertype==2){
			  if(this.usdtform.bankaccntno==''){
				  return this.$toast.fail('请输入收款链接');
			  }
			  postform = this.usdtform;
		  }
		  
		  const { data } = await this.$http.post('/home/user/addbank', postform)
		  if (data) {
		    if (data.code === 200) {
		      this.$toast.success('添加成功')
		      this.$router.back();
		    } else {
		      this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
		    }
		  }
	  },
	  selorder(type){
		this.ordertype=type;  
		this.getOrders();
	  },
	  
	  async getinfo() {
	    const { data } = await this.$http.get('/home/user/withdraw')
	    if (data) {
	      if (data.code === 200) {
	        this.balance = data.data.balance
	        this.wallet = data.data.type
	        this.config = data.data.config
	        this.product = []
	        this.wallet.forEach((type, index) => {
	          this.product.push({
	            text: type.wallet_type.toUpperCase(),
	            value: index
	          })
	        })
	      }
	    }
	  },
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/trade/get_orders')
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
    // 充值记录
    async getRecharges() {
      const { data } = await this.$http.get('/home/trade/get_recharges')
      if (data) {
        if (data.code === 200) {
          this.recharges = data.data
        }
      }
    },
    // 提币记录
    async getWithdraws() {
      const { data } = await this.$http.get('/home/trade/get_withdraws')
      if (data) {
        if (data.code === 200) {
          this.withdraws = data.data
        }
      }
    },
    // 提币记录
    async getTransfers() {
      const { data } = await this.$http.get('/home/trade/get_transfers')
      if (data) {
        if (data.code === 200) {
          this.transfers = data.data
        }
      }
    },
    change(name) {
      console.log(name)
      switch (name) {
        case 'tab2':
          this.getOrders()
          break
        case 'tab1-1':
          this.getRecharges()
          break
        case 'tab1-2':
          this.getWithdraws()
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
		.form_cell_item{
			padding: 0.6rem;
			display: flex;
			align-items: center;
			position: relative;
			&::after {
			    content: " ";
			    position: absolute;
			    left: 0;
			    bottom: 0;
			    right: 0;
			    height: 0.026667rem;
			    border-bottom: 0.026667rem solid #e5e5e5;
			    color: #e5e5e5;
			    -webkit-transform-origin: 0 100%;
			    -ms-transform-origin: 0 100%;
			    transform-origin: 0 100%;
			    -webkit-transform: scaleY(0.5);
			    -ms-transform: scaleY(0.5);
			    transform: scaleY(0.5);
			    z-index: 2;
			}
			.form_cell_label{
				width: 115px;
				font-size: 18px;
				color: #8686aa;
				text-align: left;
			}
			input{
				width: 100%;
				flex: 1;
				padding: 10px;
				box-sizing: border-box;
				background-color: transparent;
				color: #8686aa;
				outline: none;
				font-size: 18px;
				border: none;
			}
		}
	.form_item{
		padding: 0.6rem;
		input{
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			border: 1px solid #ff571e;
			border-radius: 0.4rem;
			background-color: transparent;
			    color: #ff571e;
		}
	}
	.lable_title{
		font-size: 1rem;
		text-align: left;
		padding: 0.6rem;
		color: #cad0e6;
	}
	.radio_wrap{
		display: flex;
		padding: 0.6rem;
		.radio_item{
			width: 22%;
			margin-right: 10px;
			padding: 12px 0;
			text-align: center;
			border-radius: 0.4rem;
			color: #ff571e;
			border: 1px solid #ff571e;
			font-size: 0.8rem;
		}
		.active_radio{
			background: #ff571e;
			color: #cad0e6;
		}
	}
	.info_item{
		color: #cad0e6;
		border: 0.026667rem solid #8686aa;
		width: calc(100% - 0.893333rem);
		margin: auto;
		margin-top: 2.666667rem;
		padding: 0.513333rem 0.4rem;
		border-radius: 0.513333rem;
	}
	.tableTr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 0.026667rem solid #8686aa;
		min-height: 2.7rem;
		line-height: 2.7rem;
		font-size: 1rem;
	}
	.tableTr:last-child {
		border-bottom: none;
	}
	.tableTd:first-child {
		width: 30%;
		min-width: 30%;
		word-break: break-all;
		border: none;
		text-align: left;
	}
	.tableTd:last-child {
		width: 70%;
		min-width: 70%;
		word-break: break-all;
		    border: none;
			text-align: right;
	}
	.icon-shuaqiaqiapianyinhangqia{
		color: #ff571e;
		font-size: 8rem;
	}
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    color: #8686aa;
    line-height: 1.6rem;
    font-size: 0.8rem;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
